// LoadingSpinner.tsx
import React from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Page from '../../../../layout/Page/Page';
import { FadeLoader } from 'react-spinners';

interface LoadingSpinnerProps {
  title?: string; // Optional title prop
  loading: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ title = "Loading...", loading }) => {
  return (
    <div
      title="Loading..."
      className="d-flex justify-content-center align-items-center"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        marginTop: '40vh',
        transform: 'translateY(-50%)',
        zIndex: 9999,
        overflowY: 'auto',
        display: 'flex',
      }}
    >
      <FadeLoader color="#FF8343" loading={loading} height={30} width={8} radius={4} margin={15} />
    </div>
  );
};

export default LoadingSpinner;
