// UpgradePrompt.tsx

import React from 'react';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import upgradePromptBg from '../../assets/upgradePromptBg.png';
import upgradePromptIcon from '../../assets/upgradePromptIcon.png';

type UpgradePromptProps = {
  onUpgradeClick: () => void;
};

const UpgradePrompt: React.FC<UpgradePromptProps> = ({ onUpgradeClick }) => {
  return (
    <Card
      className="m-3"
      style={{
        position: 'relative',
        overflow: 'hidden',
        background: 'linear-gradient(to right, rgba(246, 219, 175, 1.0), rgba(254, 250, 203, 1.0))',
        zIndex: 0,
      }}
    >
      <CardBody style={{ position: 'relative', }}>
        {/* Background image anchored top-right */}
        <img
          src={upgradePromptBg}
          alt="abstract background"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: 'auto',
            height: '100%',
            zIndex: -1,
          }}
        />

        {/* Main layout: row -> col-4 (icon) + col-8 (two rows of text/button) */}
        <div className="row">
          {/* Left col: Upgrade icon, centered */}
          <div className="col-4 d-flex justify-content-center align-items-center">
            <img
              src={upgradePromptIcon}
              alt="upgrade icon"
              style={{ maxWidth: '100%', height: 'auto', zIndex: 2, }}
            />
          </div>

          {/* Right col: stacked rows */}
          <div className="col-8">
            {/* Top row: text */}
            <div className="row justify-content-start" style={{ marginBottom: '0.5rem' }}>
              <div className="col-12">
                <p
                  style={{
                    fontWeight: 'bold',
                    margin: 0,
                    fontSize: '0.9rem',
                    zIndex: 2,
                  }}
                >
                  Upgrade to Gradly Pro now!
                </p>
              </div>
            </div>

            {/* Bottom row: button */}
            <div className="row justify-content-start">
              <div className="col-12">
                <Button
                  onClick={onUpgradeClick}
                  style={{
                    backgroundColor: '#FF8343',
                    borderColor: '#FF8343',
                    color: '#fff',
                    whiteSpace: 'nowrap',
                    borderRadius: '1.0rem',
                    padding: '5px 10px',   // Controls the button's size
                    fontSize: '0.8rem',       // Adjust text size
                    width: '100%',          // Adjust width if needed (e.g., '100%' for full width)
                    height: 'auto',
                    zIndex: 2,
                  }}
                >
                  Upgrade now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default UpgradePrompt;
